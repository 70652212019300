<template>
    <b-overlay :show='showLoader'>
        <b-card class="">
            <h1 class="text-secondary font">Prijavljena števila oseb in psov</h1>
            <p class="text-lg font-bold uppercase">
                (prikazani podatki veljajo za tekoče obdobje, kar se lahko razlikuje od podatkov na razdelilnikih, ki veljajo praviloma za pretekli mesec)
            </p>
            <div v-if="data && data.length > 0" class="w-100">
                <div v-for="(item, index) of data" :key="`object_${item.objekt_sifra}`" class="custom-table mt-1">
                    <hr v-if="index !== 0" class="hr my-5"/>
                    <div class="d-flex flex-column rounded-lg py-1 px-1" :style="{'background-color': '#eaf3f9', 'gap': '0.5rem'}">
                        <div class="d-flex flex-wrap" :style="{'column-gap': '0.5rem'}">
                            <h5 class="font-weight-bolder m-0">Objekt:</h5>
                            <p class="m-0">{{ item.objekt_sifra }} {{ item.objekt_naziv }}</p>
                        </div>
                        <div class="d-flex flex-wrap" :style="{'column-gap': '0.5rem'}">
                            <h5 class="font-weight-bolder m-0">Ulica in hišna številka:</h5>
                            <p class="m-0">
                                {{ item.objekt_ulica }} {{ item.objekt_hisna_stevilka }}
                            </p>
                        </div>
                        <div class="d-flex flex-wrap" :style="{'column-gap': '0.5rem'}">
                            <h5 class="font-weight-bolder m-0">Poštna številka in kraj:</h5>
                            <p class="m-0">
                                {{ item.objekt_posta }} {{ item.objekt_kraj }}
                            </p>
                        </div>
                    </div>
                    <div class="overflow-auto w-full mt-1">
                        <table class="w-100 overflow-auto">
                            <thead>
                                <tr>
                                    <th>Zaporedna št. enote:</th>
                                    <th>GURS št. enote</th>
                                    <th>Število oseb</th>
                                    <th>Število psov</th>
                                </tr>
                            </thead>
                            <tbody v-if="item.vhodi && item.vhodi.length > 0">
                                <template v-for="(vhod, vhodIdx) of item.vhodi">
                                    <tr class="vhod" :key="`object_${item.objekt_sifra}_${vhod.vhod_sifra}_${vhodIdx}`">
                                        <td colspan="4">
                                            <span>Vhod:</span> {{ vhod.vhod_sifra }} {{ vhod.vhod_naziv }}
                                        </td>
                                    </tr>
                                    <template v-if="vhod.enote_podatki && vhod.enote_podatki.length > 0">
                                        <tr v-for="(enota, idx) of vhod.enote_podatki" :key="`object_${item.objekt_sifra}_entrance_${vhod.vhod_sifra}_${idx}`">
                                            <td>{{ enota.zaporedna }}</td>
                                            <td>{{ enota.stev_stanovanja }}</td>
                                            <td>{{ enota.stev_oseb }}</td>
                                            <td>{{ enota.stev_zivali }}</td>
                                        </tr>
                                    </template>
                                    <tr :key="`object_${item.objekt_sifra}_${vhod.vhod_sifra}_${vhodIdx + 1}`">
                                        <th colspan="2">
                                            Skupaj vhod:
                                        </th>
                                        <th>
                                            {{ vhod.total_stev_oseb }}
                                        </th>
                                        <th>
                                            {{ vhod.total_stev_zivali }}
                                        </th>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4" class="text-center">
                                        Ni podatkov za prikaz
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="2">
                                        Skupaj objekt:
                                    </th>
                                    <th>
                                        {{ item.total_stev_oseb }}
                                    </th>
                                    <th>
                                        {{ item.total_stev_zivali }}
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <h3 v-else class="font-normal text-center my-8">
                Ni podatkov za prikaz
            </h3>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BCard, BOverlay} from 'bootstrap-vue'

    export default {
        components: {
            BCard,
            BOverlay
        },
        data() {
            return {
                fields: [
                    { key: 'zaporedna', label: 'Zaporedna št. enote', sortable: false, class: 'text-left', type: 0 },
                    { key: 'stev_stanovanja', label: 'GURS št. enote', sortable: false, class: 'text-left', type: 0 },
                    { key: 'stev_oseb', label: 'Število oseb', sortable: false, class: 'text-left', type: 0 },
                    { key: 'stev_zivali', label: 'Število psov', sortable: false, class: 'text-left', type: 0 }
                ],
                data: [],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                this.showLoader = true
                try {
                    const response = await this.$http.get('/api/iiportal-user/v1/e_manager/registered_number_of_people_and_dogs')
                    this.data = response.data ?? []
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
.custom-table table thead th,
.custom-table table tfoot th,
.custom-table table tbody tr th{
  vertical-align: top;
  padding: 0.72rem 2rem;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
}
.custom-table table thead tr {
  border-bottom: 2px solid #72a5d8;
  border-right: 30px solid #dbe9f5 !important;
  border-left: 30px solid #dbe9f5 !important;
  color: #72a5d8 !important;
  font-weight: bold;
  background: #dbe9f5 !important;
}
.custom-table table tbody tr {
  background-color: #eaf3f9 !important;
  border-top: 1px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
}
.custom-table table tbody tr.vhod {
  background-color: #E7E6E6 !important;
  border-right: 30px solid #E7E6E6;
  border-left: 30px solid #E7E6E6;
}
.custom-table table tbody td {
  padding: 0.72rem 2rem;
  border-top: 1px solid #ebe9f1;
}
.custom-table table tfoot {
  background-color: #eaf3f9 !important;
  border-top: 2px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
  border-bottom: 20px solid #eaf3f9;
  font-weight: bold;
}
.hr{
  border-top: 2px solid #E6E6E6;
  width: 100%;
}
</style>